import React, { Fragment } from 'react';
import CinemaLogoSvg from 'Images/cinema/logo_white.svg';
import SportLogoSvg from 'Images/sport/logo_white.svg';
import { getAppIndustry } from 'Core/helpers';
import { FormattedMessage } from 'react-intl';

const Logo = () => {
  const industry = getAppIndustry();
  const industryLogoSrc = industry === 'sport' ? SportLogoSvg : CinemaLogoSvg;

  return (
    <div className={'logo'}>
      <img src={industryLogoSrc} alt={`${industry}-logo`} />
      <h1 className="white thin">
        <FormattedMessage id="login.welcome_message"/>
      </h1>
    </div>
  );
};

export default Logo;
